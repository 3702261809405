(function () {
	document.addEventListener("DOMContentLoaded", () => {
		const productsSliderEl = document.querySelectorAll(".products-slider-root");

    productsSliderEl.forEach((sliderEl) => {
      var productsSlider = new Swiper(sliderEl, {
        speed: 750,
        spaceBetween: 20,
        on: {
          afterInit: function () {
            // remove class added to prevent flash of unstyled content
            sliderEl.classList.remove("hide-slider");
          }
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          1280: {
            slidesPerView: 5
          },
          960: {
            slidesPerView: 4
          },
          768: {
            slidesPerView: 3
          },
          480: {
            slidesPerView: 2
          },
          360: {
            slidesPerView: 1,
            speed: 1000
          }
        }
      });
    });
	});
})();
